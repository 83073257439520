

























































































































































































































































@import './../../../../../assets/styles/components/formularios';
@import './../../../../../assets/styles/components/modal-cadastro';
.v-text-field--outlined fieldset {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid;
  border-radius: 0;
}
.v-text-field--outlined.v-input--is-focused fieldset{
  border: 0;
  border-bottom:2px solid #1cb4ff;
}
